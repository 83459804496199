<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
		<div class="w-100 h-auto flex flex-column">
			<el-form ref="form" :model="form" :rules="rules" label-width="130px" size="small">
				<el-form-item label="学期" prop="s_id">
				  <el-select v-model="form.s_id" placeholder="请选择学期" style="width: 100%;">
				    <el-option :value="item.id" :label="item.year+' '+item.tag" v-for="(item,index) in semester" :key="index"/>
				  </el-select>
				</el-form-item>
				<el-form-item label="课题名称" prop="title">
				  <el-input v-model="form.title" placeholder="请输入课题名称" type="text" clearable />
				</el-form-item>
				<el-form-item label="课题状态" prop="type">
				  <el-radio-group v-model="form.type">
				  	<el-radio :label="1">立项</el-radio>
				  	<el-radio :label="2">结题</el-radio>
				  </el-radio-group>
				</el-form-item>
				<el-form-item label="课题级别" prop="grade">
				  <el-radio-group v-model="form.grade">
				  	<el-radio :label="1">校级</el-radio>
				  	<el-radio :label="2">市级</el-radio>
				  	<el-radio :label="3">省级及以上</el-radio>
				  </el-radio-group>
				</el-form-item>
				<el-form-item label="课题主持人" prop="host_persons">
					<div class="w-100 flex flex-column">
						<div class="w-100 flex align-center mb-1" v-for="(item,index) in host_person" :key="item.id">
							<el-input v-model="item.name" placeholder="请输入课题成员名称" type="text" clearable @focus="selUsers('zcr',index)"/>
							<el-input v-model="item.code" placeholder="请输入课题成员编码" type="text" clearable style="margin:0 10px;"/>
							<el-input v-model="item.score" placeholder="请输入课题成员分数" type="number" clearable style="margin:0 10px;"/>
							<el-tooltip class="item" effect="dark" content="删除" placement="top">
								<el-button type="danger" size="mini" @click="delZcrSpec(index)">
									<i class="el-icon-minus"></i>
								</el-button>
							</el-tooltip>
						</div>
						<div class="w-100 flex align-center" v-if="host_person.length <2">
							<el-button type="danger" size="mini"  @click="addZcrParams">
								<i class="el-icon-plus"></i>添加
							</el-button>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="其他成员">
					<div class="w-100 flex flex-column">
						<div class="w-100 flex align-center mb-1" v-for="(item,index) in other_person" :key="item.id"  v-dragging="{ item: item, list: other_person, group: 'item' }">
							<el-input v-model="item.name" placeholder="请输入课题其他成员名称" type="text" clearable @focus="selUsers('other',index)"/>
							<el-input v-model="item.code" placeholder="请输入课题其他成员编码" type="text" clearable style="margin-left:10px;"/>
							<el-input v-model="item.score" placeholder="请输入课题其他成员分数" type="number" clearable style="margin: 0 10px;"/>
							<el-tooltip class="item" effect="dark" content="删除" placement="top">
								<el-button type="danger" size="mini" @click="delSpec(index)">
									<i class="el-icon-minus"></i>
								</el-button>
							</el-tooltip>
							<el-tooltip class="item" effect="dark" content="拖拽" placement="top">
								<el-button type="danger" size="mini">
									<i class="el-icon-sort"></i>
								</el-button>
							</el-tooltip>
						</div>
						<div class="w-100 flex align-center">
							<el-button type="danger" size="mini"  @click="addParams">
								<i class="el-icon-plus"></i>添加
							</el-button>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="上传材料" prop="files">
					<div class="flex flex-column flex-wrap">
						<div class="flex mr-1 mb-2">
							<el-upload
							  v-for="(item,index) in files" :key="index"
							  class="upload-border"
							  action="/manage/file/upload"
							  :show-file-list="false"
							  :on-success="uploadSuccess"
							  :before-upload="beforeUpload">
							  <div class="position-relative flex align-center flex-wrap w-100 overflow-hidden" style="height: 100px;" v-if="item.id">
								<img :src="item.path" style="width: 100px;height: 100px;" v-if="item.ext == 'jpg' || item.ext == 'jpeg' || item.ext == 'png'">
							  	<span style="width: 100px;word-wrap:break-word;word-break:break-all;overflow: hidden;" v-else>{{item.name || ''}}</span>
							  	<i class="el-icon-delete text-white position-absolute flex-all" style="width: 25px;height: 25px;top: 0;right: 0;background-color: rgba(0,0,0,0.4);cursor: pointer;" @click.stop="delFile(index)"></i>
							  </div>
							  <i v-else class="el-icon-plus uploader-icon"></i>
							</el-upload>
							<el-upload
							  v-if="files.length < 5"
							  class="upload-border"
							  action="/manage/file/upload"
							  :show-file-list="false"
							  :on-success="uploadSuccess"
							  :before-upload="beforeUpload">
							  <i class="el-icon-plus uploader-icon"></i>
							</el-upload>
						</div>
						<small class="d-block text-secondary" style="line-height: 20px;">上传照片及相关资料</small>
					</div>
				</el-form-item>
				<el-form-item label="课题内容" prop="content">
				   <tinymce ref="editor" :key="tinymceFlag" v-model="form.content" />
				</el-form-item>
			</el-form>
			<div class="text-danger mt-2" style="line-height: 25px;">
			  备注：<br/>
			  1、一个校级课题10分。<br/>
			  2、一个市级课题15分。<br/>
			  3、一个省级及以上课题20分。<br/>
			  4、课题主持人得满分，其他主持人有主持人根据贡献大小确定分数，但得分一起不能超过总分。立项得一半分，结题得满分。<br/>
			  <img src="../../../assets/images/ktyj_score.png" style="width: 800px;height: 180px;">
			</div>
			<div class="w-100 flex-all mt-2">
			  <el-button size="medium" @click="cancel">取 消</el-button>
			  <el-button type="primary" size="medium" @click="handleSubmit" style="margin-left: 30px;">确 定</el-button>
			</div>
		</div>
		</el-card>
		<!-- 选择用户 -->
		<el-dialog :visible.sync="openData" width="800px" :close-on-click-modal="false" append-to-body :title="title">
			<el-table
			  :data="userData"
			  style="width: 100%">
			  <el-table-column
			  label="ID"
			  align="center"
			  prop="id">
			  </el-table-column>
			  <el-table-column
			  label="名称"
			  align="center"
			  prop="username">
			  </el-table-column>
			  <el-table-column
			  label="编码"
			  align="center"
			  prop="code">
			  </el-table-column>
			  <el-table-column
			  label="手机"
			  align="center"
			  prop="mobile">
			  </el-table-column>
			  <el-table-column
			  width="200"
			  align="right">
			  <template slot="header" slot-scope="scope">
				<el-input
				v-model="page.keyword"
				size="mini"
				placeholder="输入关键字搜索">
				  <el-button slot="append" icon="el-icon-search" size="mini" @click="getUsers"></el-button>
				</el-input>
			  </template>
			  <template slot-scope="scope">
				<el-button
				type="danger"
				size="mini"
				@click="handleSel(scope.$index, scope.row)">选择</el-button>
			  </template>
			  </el-table-column>
			</el-table>
			<!-- 分页 -->
			<span slot="footer" class="dialog-footer">
			  <div class="px-2" style="flex: 1;">
			   <pagination
				 :total="page.total"
				 auto-scroll
				 :page.sync="page.page"
				 :limit.sync="page.limit"
				 @pagination="getUsers"
			   />
			  </div>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import GlobalTips from "@/components/GlobalTips";
import common from "@/common/mixins/common.js"
import Breadcrumb from '@/components/Breadcrumb';
import tinymce from "@/components/common/tinymce.vue"
export default {
	inject:['app'],
	mixins:[common],
	components: {
		GlobalTips,
		Breadcrumb,
		tinymce
	},
	data() {
		return {
			preUrl: 'user_ktyj',
			autoRequest: false,
			queryForm: {
				page: 1,
				limit: 10,
				keyword: '',
			},
			form:{
				s_id:'',
				title:'',
				type:'',
				grade:'',
				content:'',
				host_persons:null,
				files:null,
			},
			rules:{
				s_id: [
				  { required: true, message: '请选择学期', trigger: ['blur','change'] }
				],
				title: [
				  { required: true, message: '请输入课题名称', trigger: 'blur' }
				],
				type: [
				  { required: true, message: '请选择课题状态', trigger: ['blur','change'] }
				],
				grade: [
				  { required: true, message: '请选择课题级别', trigger: ['blur','change'] }
				],
				host_persons: [
				  { required: true, message: '请填写课题主持人', trigger: ['blur','change'] }
				],
				content: [
				  { required: true, message: '请填写课题内容', trigger: ['blur','change'] }
				],
				files: [
				  { required: true, message: '请上传材料', trigger: ['blur','change'] }
				],
			},
			tinymceFlag:0,
			host_person:[],
			other_person:[],
			page: {
				page: 1,
				limit: 10,
				total:0,
				keyword: '',
			},
			openData:false,
			userData:[],
			type:'zcr',
			index:0,
			info:{},
			files:[],
			fileList:[],
		}
	},
	mounted() {
		this.getsemester()
		if(this.$route.query.info){
			 this.form = this.$route.query.info
			 this.host_person = this.$route.query.info.host_persons ? this.$route.query.info.host_persons : []
			 this.other_person = this.$route.query.info.params ? this.$route.query.info.params : []
		}else{
			this.getAdminInfo()
		}
		if(this.$route.query.info.files){
		    this.files = this.$route.query.info.files
		}
	},
	methods:{
		getAdminInfo(){
			this.axios.get('/manage/admin/info').then(res=>{
				if(res.status){
					this.info = res.data
				}
			})
		},
		reset() {
		  this.form = {
			 s_id:'',
			 title:'',
			 type:'',
			 grade:'',
			 content:'',
			 host_persons:null,
			 files:null,
		  }
		  this.resetForm('form')
		},
		addZcrParams(){
			if(this.form.type == ''){
				this.$message.error('请先选择课题状态')
				return 
			}
			if(this.form.grade == ''){
				this.$message.error('请先选择课题级别')
				return 
			}
			if(this.host_person.length == 0){
				if(this.form.type == 1){
					if(this.form.grade == 1){
						this.host_person.push({name:this.info.username,code:this.info.code,score:5})
					}else if(this.form.grade == 2){
						this.host_person.push({name:this.info.username,code:this.info.code,score:7.5})
					}else if(this.form.grade == 3){
						this.host_person.push({name:this.info.username,code:this.info.code,score:10})
					}
				} else if(this.form.type == 2){
					if(this.form.grade == 1){
						this.host_person.push({name:this.info.username,code:this.info.code,score:10})
					}else if(this.form.grade == 2){
						this.host_person.push({name:this.info.username,code:this.info.code,score:15})
					}else if(this.form.grade == 3){
						this.host_person.push({name:this.info.username,code:this.info.code,score:20})
					}
				}
			}else{
				this.host_person.push({name:'',code:'',score:''})
			}
		},
		delZcrSpec(index){
			this.host_person.splice(index,1)
			this.$forceUpdate()
		},
		addParams(){
			this.other_person.push({name:'',code:'',score:''})
		},
		delSpec(index){
			this.other_person.splice(index,1)
			this.$forceUpdate()
		},
		handleSubmit() {
		  this.form.files = this.files
		  this.$refs.form.validate(valid => {
			  // if(this.host_person.length == 0){
			  // 	this.$message.error('请填写课题申请人')
			  // 	return 
			  // }
			   this.form.host_persons = this.host_person
		    if (valid) {
			  this.form.params = this.other_person
		      if (this.form.id === undefined) {
		        this.axios.post('/manage/'+this.preUrl + '/add', this.form).then(res => {
		          if (res.status) {
		            this.$message.success('申请成功')
		            this.$router.go(-1);
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		      } else {
		        this.axios.put('/manage/'+this.preUrl + '/edit/' + this.form.id, this.form).then(res => {
		          if (res.status) {
		            this.$message.success('修改成功')
		            this.$router.go(-1);
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		      }
		    }
		  })
		},
		cancel(){
			this.$router.go(-1);
		},
		selUsers(type,index){
			 this.type = type
			 this.index = index
			 this.getUsers()
			 this.$nextTick(()=>{
			   this.title = '人员列表'
			   this.openData = true
			 })
		},
		getUsers(){
			this.axios.get('/manage/admin/all',{
			  params: {
				page:this.page.page,
				limit:this.page.limit,
				keyword:this.page.keyword,
				type:this.page.type,
			  }
			}).then(res=>{
			  this.page.total = res.data.total
			  this.userData = res.data.list
			})
		},
		handleSel(index,item){
			if(this.type == 'zcr'){
				this.host_person[this.index]['name'] = item.username
				this.host_person[this.index]['code'] = item.code
			}else{
				this.other_person[this.index]['name'] = item.username
				this.other_person[this.index]['code'] = item.code
			}
			this.openData = false
			this.title = '新增'
		},
		uploadSuccess(e){
		   if(e.status){
			 this.files.push(e.data)
		   }
		},
		beforeUpload(file){
			let fileArr = file.name.split('.')
			let suffix = fileArr[fileArr.length - 1]
			//只能导入图片文件
			if (!/(jpg|jpeg|png|gif|pdf|doc|docx)/i.test(suffix)) {
				this.$message.error('文件格式不正确')
				return false
			}
			return true
		},
		delFile(index){
			this.files.splice(index,1)
		},
	},
}
</script>

<style>
</style>